<template>
    <modal
        class="suggest-topic"
        :adaptive="true"
        name="suggest-topic"
        width="600"
        height="auto"
        @before-close="beforeClose"
    >
        <div class="close-modal" @click="$modal.hide('suggest-topic')">
            <img src="@assets/img/icons/x.svg" alt="X" width="15px">
        </div>
        <div class="suggest-topic-container">
            <h1 class="title">
                Suggest Topic
            </h1>
            <div class="form-group topic-name" @click="focusTopicNameInput">
                <label for="topic-name">Topic Name</label>
                <div class="d-flex align-items-center">
                    <div
                        contenteditable
                        class="form-control"
                        @input="(event) => suggestedTopic.name = event.target.textContent"
                    />
                    <span v-if="suggestedTopic.emoji != ''" class="suggested-emoji">{{ suggestedTopic.emoji }}</span>
                </div>
            </div>
            <div class="form-group">
                <vue-tabs class="emojis-tabs">
                    <v-tab v-for="(emojiGroup, category) in emojis" :key="category" :title="category">
                        <div class="emojis-container">
                            <button
                                v-for="(emoji, emojiName) in emojiGroup"
                                :key="emojiName"
                                class="emoji btn"
                                @click="suggestedTopic.emoji = emoji"
                            >
                                {{ emoji }}
                            </button>
                        </div>
                    </v-tab>
                </vue-tabs>
            </div>
            <div class="form-group">
                <label>Select related Topics</label>
                <topics-multiselect
                    v-model="suggestedTopic.relatedTopics"
                    :show-no-results="false"
                    placeholder="Add related topic"
                    @remove-topic="removeTopic"
                />
            </div>
            <div class="d-flex justify-content-end">
                <button
                    class="btn btn-primary next-button"
                    :disabled="!isValidSuggestedTopic"
                    @click="suggestTopic"
                >
                    Save
                </button>
            </div>
        </div>
    </modal>
</template>

<script>
import emojis from "@utils/emojis.js";
import { VueTabs, VTab } from "vue-nav-tabs";
import "vue-nav-tabs/themes/vue-tabs.css";

export default {
    name: "SuggestTopic",
    components: {
        VueTabs,
        VTab,
        topicsMultiselect: () => import(/* webpackChunkName: "topics-multiselect" */ "@c/molecules/topics-multiselect")
    },
    data() {
        return {
            emojis,
            suggestedTopic: {
                name: "",
                emoji: "",
                relatedTopics: []
            }
        };
    },
    computed: {
        isValidSuggestedTopic() {
            return Boolean(this.suggestedTopic.name != "" && this.suggestedTopic.emoji != "");
        }
    },
    methods: {
        removeTopic(topicToDelete) {
            const topicToDeleteIndex = this.suggestedTopic.relatedTopics.findIndex(topic => topic.id == topicToDelete.id);
            this.suggestedTopic.relatedTopics.splice(topicToDeleteIndex, 1);
        },
        focusTopicNameInput() {
            const el = document.querySelector(".topic-name .form-control");
            el.focus();
            if (typeof window.getSelection != "undefined"
                    && typeof document.createRange != "undefined") {
                var range = document.createRange();
                range.selectNodeContents(el);
                range.collapse(false);
                var sel = window.getSelection();
                sel.removeAllRanges();
                sel.addRange(range);
            } else if (typeof document.body.createTextRange != "undefined") {
                var textRange = document.body.createTextRange();
                textRange.moveToElementText(el);
                textRange.collapse(false);
                textRange.select();
            }
        },
        suggestTopic() {
            const topicToSuggest = {
                name: `${this.suggestedTopic.name.trim()} ${this.suggestedTopic.emoji}`,
                related: this.suggestedTopic.relatedTopics
            }

            axiosV2.post(`/suggested-topics`, topicToSuggest).then(({ data }) => {
                this.$emit("suggested-topic", data)
                this.$modal.hide("suggest-topic");
                this.$modal.show("suggest-topic-thanks", { suggestedTopic: data });
            }).catch((error) => {
                console.log(error.response.data.errors.message);

                this.$notify({
                    group: "app-notifications",
                    type: "error",
                    text: error.response.data.errors.message,
                    duration: 3000
                });
            })
        },
        beforeClose() {
            this.suggestedTopic.name = "";
            this.suggestedTopic.emoji = "";
            this.suggestedTopic.relatedTopics = [];
        }
    }
}
</script>

<style lang="scss" scoped>
.suggest-topic {
    z-index: 99999;

    .close-modal {
        position: absolute;
        right: 40px;
        top: 40px;
        cursor: pointer;
        z-index: 3;

        @media(max-width: $sm) {
            right: 15px;
            top: 15px;

            img {
                width: 15px;
            }
        }
    }

    /deep/ .vm--modal {
        background-color: #0F0F10;
        border-radius: 10px;
        box-shadow: none;
        padding: 40px;
        overflow: visible;

        .suggest-topic-container {
            height: 100%;
            display: flex;
            flex-direction: column;

            .title {
                text-align: center;
            }

            .topic-name {
                border-bottom: 1px solid rgba(235, 235, 245, 0.3);
                cursor: text;

                .form-control {
                    width: fit-content;
                    min-width: 2px;
                    border-bottom: 0;
                }

                .suggested-emoji {
                    display: block;
                    margin-left: 5px;
                    font-size: 18px;
                    line-height: 30px;
                }

                &:focus-within {
                    border-color: $primary-color;
                }
            }

            .emojis-tabs {
                .nav-tabs {
                    border: 0;
                    display: flex;
                    justify-content: space-between;

                    .tab {
                        .tabs__link {
                            padding: 0;
                            border: 0;
                            color: rgba(235, 235, 245, 0.6);

                            span {
                                padding-top: 0;
                            }

                            &.active_tab {
                                background-color: transparent;
                                border-bottom: 1px solid #05A88F;
                            }

                            &:hover {
                                background-color: transparent;
                            }
                        }
                    }
                }

                .tab-content {
                    .emojis-container {
                        margin-top: 20px;
                        height: 125px;
                        overflow-y: scroll;

                        .emoji {
                            padding: 0 4px;
                            font-size: 30px;

                            &:focus {
                                box-shadow: none;
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>
